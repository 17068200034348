<template>
  <section id="alerta-push-form">
    <SectionTabs :tabs="tab_component" />

    <div class="row mt-3">
      <div class="col-12">
        <h3 class="section-head-title ps-0 mb-3">
          Completa los siguientes campos para agregar una nueva alerta.
        </h3>
      </div>
    </div>

    <div class="card card-custom border-round-10 card-shadow border-0 mb-3">
      <div class="card-body d-flex flex-column mh-700">
        <div class="row flex-grow-1 mt-3">
          <div class="col-12 col-lg-4 d-flex flex-column mt-3 mt-lg-0">
            <h5 class="font-main text-secondary">Segmentación</h5>
            <div class="card flex-grow-1">
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <div class="alert alert-info text-center">
                      Para comenzar elige un segmento
                    </div>
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-12">
                    <v-select
                      class="selvue-custom"
                      :options="filtersAlerts"
                      v-model="sel_segmento"
                      :clearable="false"
                      placeholder="Seleccionar"
                    >
                    </v-select>
                  </div>
                </div>

                <div
                  v-if="sel_segmento.filtros.length !== 0"
                  class="row mb-3"
                >
                  <div class="col-12">
                    <label class="text-secondary">
                      Filtro de {{ sel_segmento.label }}
                    </label>
                    <v-select
                      class="selvue-custom"
                      :options="filterList"
                      v-model="select_filter"
                      :clearable="false"
                      placeholder="Seleccionar"
                    >
                    </v-select>
                  </div>
                </div>

                <div class="border-bottom mb-2"></div>

                <div class="row mb-3">
                  <div class="col-12">
                    <label class="text-secondary"> Vincula un módulo </label>
                    <v-select
                      class="selvue-custom"
                      :options="modules_list"
                      v-model="select.module"
                      label="name"
                      :clearable="false"
                      placeholder="Seleccionar"
                    >
                    </v-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-4 d-flex flex-column mt-3 mt-lg-0">
            <h5 class="font-main text-secondary">Mensaje</h5>
            <div class="card flex-grow-1">
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <label class="text-secondary">Añade el mensaje</label>
                    <textarea
                      class="form-control textarea-custom"
                      maxlength="500"
                      v-model="mensaje"
                      rows="3"
                    >
                    </textarea>
                    <div class="form-text text-end">
                      {{ mensaje.length }}/150
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-4 d-flex flex-column mt-3 mt-lg-0">
            <h5 class="font-main text-secondary">Programar</h5>
            <div class="card flex-grow-1">
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <div
                      class="alert alert-light border d-flex align-items-center justify-content-between"
                    >
                      <label
                        for="respuestaCorrecta"
                        class="form-check-label link-cursor text-secondary"
                      >
                        ¿Programar esta alerta?
                      </label>
                      <div class="form-check form-switch">
                        <input
                          id="respuestaCorrecta"
                          class="form-check-input link-cursor"
                          type="checkbox"
                          role="switch"
                          v-model="calendar_switch"
                          @click="switch_comprobar"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="calendar_switch" class="row">
                  <div class="col-12">
                    <v-date-picker
                      locale="es"
                      color="yellow"
                      class="add__date"
                      style="width: 100%;"
                      :key="key_calendar"
                      :min-date="min_date"
                      @dayclick="onDayClick"
                      v-model="calendar"
                    />
                  </div>
                </div>

                <div v-if="calendar_switch && calendar !== ''" class="row mt-3">
                  <div class="col-12">
                    <label class="text-secondary">
                      <font-awesome-icon
                        :icon="['fa-regular', 'clock']"
                        class="color-main"
                      />
                      Hora de publicación
                    </label>
                    <input
                      class="form-control input-custom"
                      type="text"
                      v-mask="maskDateTime"
                      v-model="dateTime"
                      placeholder="hh:mm"
                      @keyup="comprobar_hora"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div
              class="d-flex align-items-center justify-content-end pt-2 mt-4 border-top border-2"
            >
              <button
                class="btn btn-custom-color-white border border-round-50 mw-100 me-2"
                @click="onCancel()"
              >
                Volver
              </button>
              <button
                class="btn btn-custom-color-blue border-round-50 mw-100"
                :disabled="!comprobarAnd()"
                :class="{ 'btn-disabled': !comprobarAnd() }"
                @click="comprobarAnd() && add_or_edit()"
              >
                <span v-if="!btn_editar">Agregar</span>
                <span v-if="btn_editar">Guardar</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Status
      v-if="open_modal_status"
      :msg="modal_status_msg"
      :status="modal_status"
      @close="closeStatus"
    />
    <Spinner v-if="show_spinner" />
  </section>
</template>

<script>
import Status from "../Modales/Status.vue";
import Spinner from "../Spinner.vue";
import { mapActions, mapState } from "vuex";
import SectionTabs from "../Section/SectionTabs.vue";
import moment from "moment-timezone";

export default {
  components: { Status, Spinner, SectionTabs },
  name: "AlertaPushAdd",
  data() {
    return {
      tab_component: [
        {
          name: "Inicio",
          route: "push-list",
        },
        {
          name: "Agregar",
          route: "push-add",
        },
      ],
      id_usuario: this.$ls.get("user").id_usuario,
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      modulos: this.$ls.get("modules"),
      segment: "Transversal",
      open_modal_status: false,
      show_spinner: false,
      modal_status_msg: "",
      modal_status: true,
      mensaje: "",
      open_select_list: "",
      select_seg: {},
      select: {
        workplace: {
          id: "",
          name: "Seleccionar",
        },
        area: {
          id: "",
          name: "Seleccionar",
        },
        sindicato: {
          id: "",
          name: "Seleccionar",
        },
        gender: {
          id: "",
          name: "Seleccionar",
        },
        module: {
          id: "",
          name: "Seleccionar",
        },
      },
      select_filter: {id: "", label: "Seleccionar",value: "Seleccionar"},
      //workplace_list: [],
      //area_list: [],
      //sindicato_list: [],
      gender_list: [
        {
          id: "1",
          name: "Femenino",
        },
        {
          id: "0",
          name: "Masculino",
        },
      ],
      //modules_list: [],

      calendar_switch: false,
      dateTime: "",
      datetime_valited: "",
      maskDateTime: "##:##",
      key_calendar: 0,
      min_date: new Date(),
      calendar: "",
      calendar_id: "",

      //alert_edit: {},
      day_calendar: false,

      //FF
      btn_editar: false,
      sel_segmento: {
        id: "",
        label: "Seleccionar",
        value: "",
        filtros: [],
      },
      segmentos: [
        {
          id: 0,
          label: "Transversal",
          value: "Transversal",
        },
        {
          id: 1,
          label: "Gerencia",
          value: "area",
        },
        {
          id: 2,
          label: "Lugar de Trabajo",
          value: "place",
        },
        {
          id: 3,
          label: "Sindicato",
          value: "sindicato",
        },
        {
          id: 4,
          label: "Género",
          value: "genero",
        },
      ],
    };
  },
  computed: {
    ...mapState("alertPushModule", [
      "workplace_list",
      "area_list",
      "sindicato_list",
      "modules_list",
      "alert_edit",
      "filtersAlerts",
    ]),
    filterList () {
      return [{id: "", label: "Seleccionar",value: "Seleccionar"}, ...this.sel_segmento.filtros]
    }
  },
  watch: {
    sel_segmento() {
      this.open_select_list = false;
      this.select_filter = {id: "", label: "Seleccionar",value: "Seleccionar"};
      this.select.workplace = "";
      this.select.area = "";
      this.select.sindicato = "";
      this.select.gender = "";
    },
  },
  async created() {
    await Promise.all([
      this.getSucursales(),
      this.getAreas(),
      this.getSindicatos(),
      this.getModules(),
      this.getFiltrosAlerta(),
    ]);
    if (this.$route.params.id != undefined && this.$route.params.id != null) {
      this.tab_component[1].name = "Editar";
      this.getAlertToEdit(this.$route.params.id);
    }
  },
  methods: {
    ...mapActions("alertPushModule", [
      "createAlertPushAction",
      "editAlertPushAction",
      "getAlertaPushByID",
      "getSucursalesAlerta",
      "getAreasAlerta",
      "getSindicatosAlerta",
      "getModulosAlerta",
      "getFiltrosAlerta",
    ]),
    switch_comprobar() {
      // console.log("calendar interruptor", this.calendar_switch);
      if (this.calendar_switch == true) {
        this.dateTime = "";
        this.key_calendar = 0;
        this.calendar = "";
        this.calendar_id = "";
        this.datetime_valited = "";
      } else {
        this.dateTime = "";
        this.key_calendar = 0;
        this.calendar = "";
        this.calendar_id = "";
        this.datetime_valited = "";
      }
    },
    comprobar_hora() {
      if (this.dateTime.length == 5) {
        let hora = this.dateTime[0] + this.dateTime[1];
        let minutos = this.dateTime[3] + this.dateTime[4];
        let hoy = new Date();
        if (this.get_date(this.calendar) == this.get_date(hoy)) {
          // CALENDAR
          let tiempo_calendar = this.get_hours(this.calendar);
          let hora_calendar = tiempo_calendar[0] + tiempo_calendar[1];
          let minutos_calendar = tiempo_calendar[3] + tiempo_calendar[4];
          let total_minutos_calendar =
            parseInt(hora_calendar) * 60 + parseInt(minutos_calendar);
          // HOY
          let total_minutos_actual = parseInt(hora) * 60 + parseInt(minutos);
          if (total_minutos_actual > total_minutos_calendar) {
            if (
              parseInt(hora, 10) >= 0 &&
              parseInt(hora) < 24 &&
              parseInt(minutos, 10) >= 0 &&
              parseInt(minutos) < 60
            ) {
              this.datetime_valited = this.dateTime;
            } else {
              this.datetime_valited = "";
            }
          } else {
            this.datetime_valited = "";
          }
        } else {
          if (
            parseInt(hora, 10) >= 0 &&
            parseInt(hora) < 24 &&
            parseInt(minutos, 10) >= 0 &&
            parseInt(minutos) < 60
          ) {
            this.datetime_valited = this.dateTime;
          } else {
            this.datetime_valited = "";
          }
        }
      } else {
        this.datetime_valited = "";
      }
    },
    // Llama a agregar o editar segun $route.name
    add_or_edit() {
      if (this.$route.name == "push-add") {
        return this.addAlert(); // llama a addAlert
      }
      if (this.$route.name == "push-edit") {
        return this.editAlert(); // llama a editAlert
      }
    },
    // Agregar notificación
    async addAlert() {
      let contenidos = this.varAdd();
      //console.log('CONTENIDO', contenidos);
      this.show_spinner = true;
      let data_ = contenidos;
      this.createAlertPushAction(data_)
        .then((res) => {
          if (res != null) {
            this.show_spinner = false;
            this.$toast.open({
              message: "Tú notificación ha sido añadido correctamente",
              type: "success",
              duration: 6000,
              position: "top-right",
            });
          } else {
            this.show_spinner = false;
            this.$toast.open({
              message:
                "El proceso de crear alerta push ha fallado, favor contactar a soporte@carpetres.cl",
              type: "error",
              duration: 0,
              position: "top-right",
            });
          }
          this.$router.push({ name: "push-list" });
        })
        .catch(() => {
          this.show_spinner = false;
          this.$toast.open({
            message:
              "El proceso de crear alerta push ha fallado, favor contactar a soporte@carpetres.cl",
            type: "error",
            duration: 0,
            position: "top-right",
          });
        });
    },
    // Parametros para agregar notificación
    varAdd() {
      let segmentacion = `${this.select_filter.id}`;
      segmentacion = segmentacion != "" ? [segmentacion] : [];
      let push = {
        id_empresa: this.id_empresa,
        titulo:
          this.select.module.id == "" ? "General" : this.select.module.name,
        texto: this.mensaje,
        tipo_segmentacion: `${this.sel_segmento.id}`,
        segmentaciones:segmentacion,
        id_modulo: this.select.module.id == "" ? "1" : this.select.module.id,
        id_objeto: "0",
        fecha_notificacion:
          this.calendar_id != ""
            ? this.convertToUTCStartOfDay(`${this.calendar_id} ${this.dateTime}:00`)
            : "",
        id_usuario: this.id_usuario,
      };
      return push;
    },
    // Editar notificación
    async editAlert() {
      let contenidos = this.varEdit(this.$route.params.id);
      this.show_spinner = true;
      let data_ = contenidos;
      await this.editAlertPushAction(data_)
        .then((res) => {
          //let resp = res.data.editarNotificacionPush;
          if (res != null) {
            this.show_spinner = false;
            this.$toast.open({
              message: "Tú notificación ha sido editada correctamente",
              type: "success",
              duration: 6000,
              position: "top-right",
            });
          } else {
            this.show_spinner = false;
            this.$toast.open({
              message:
                "El proceso de editar alerta ha fallado, favor contactar a soporte@carpetres.cl",
              type: "error",
              duration: 0,
              position: "top-right",
            });
          }
          this.$router.push({ name: "push-list" });
        })
        .catch(() => {
          this.show_spinner = false;
          this.$toast.open({
            message:
              "El proceso de editar alerta ha fallado, favor contactar a soporte@carpetres.cl",
            type: "error",
            duration: 0,
            position: "top-right",
          });
        });
    },
    // Parametros para editar notificación
    varEdit(id) {
      let segmentacion = `${this.select_filter.id}`;
      segmentacion = segmentacion != "" ? [segmentacion] : [];
      let push = {
        idNotificacion: id,
        idEmpresa: this.id_empresa,
        titulo:
          this.select.module.id == "" ? "General" : this.select.module.name,
        texto: this.mensaje,
        tipoSegmentacion: `${this.sel_segmento.id}`,
        segmentaciones:segmentacion,
        idModulo: this.select.module.id == "" ? "1" : this.select.module.id,
        idObjeto: "0",
        fechaNotificacion:
          this.calendar_id != ""
            ? this.convertToUTCStartOfDay(`${this.calendar_id} ${this.dateTime}:00`)
            : "",
        idUsuario: this.id_usuario,
      };
      return push;
    },
    // Traer notificacion a editar
    async getAlertToEdit(id) {
      this.btn_editar = true;
      this.show_spinner = true;
      let data_ = {
        idUsuario: this.id_usuario,
        idNotificacion: id,
        idEmpresa: this.id_empresa,
      };
      await this.getAlertaPushByID(data_)
        .then((res) => {
          let resp = res;
          this.show_spinner = false;
          if (resp.id_notificacion != null) {
            if (this.verCaso(resp) == false) {
              this.modal_status = false;
              this.modal_status_msg =
                "Ha ocurrido un problema, intenta nuevamente";
              this.open_modal_status = true;
            }
            //this.alert_edit = resp;
            this.mensaje = this.alert_edit.texto_notificacion;
            //this.select.module.name = this.alert_edit.titulo_notificacion;
            if (this.alert_edit.fecha_notificacion != null) {
              this.calendar_switch = true;
              this.dateTime = this.get_hours(
                this.alert_edit.fecha_notificacion
              );
              this.calendar = this.get_date(this.alert_edit.fecha_notificacion);
              this.calendar_id = this.calendar;
              this.datetime_valited = this.dateTime;
            }
            // Segmentacion
            const seg = this.alert_edit.segmentacion;
            
            const filtersAlerts = this.filtersAlerts.find(
              ({ id }) => id == seg.tipo_segmentacion.id_elementos_segmentacion
            )
            if (filtersAlerts != undefined) {
              this.sel_segmento = filtersAlerts
            }else{
              this.sel_segmento = this.filtersAlerts[0]
            }
            
            this.$nextTick(() => {
              const select_filter = this.sel_segmento.filtros.find(
                ({ id }) => id == seg.filtros[0].id
              ) 
              if(select_filter != undefined){
                this.select_filter = select_filter;
              }else{
                this.select_filter = { id: "", label: "Seleccionar", value: "Seleccionar" }

              }
            });

            this.modules_list.forEach((element) => {
              if (element.name === this.alert_edit.titulo_notificacion) {
                this.select.module.id = element.id;
                this.select.module.name = element.name;
              }
            });
          } else {
            this.alert_edit = null;
            this.open_modal_status = true;
            this.modal_status = false;
            this.modal_status_msg =
              "Ha ocurrido un problema, intenta nuevamente";
          }
        })
        .catch(() => {
          this.open_modal_status = true;
          this.modal_status = false;
          this.modal_status_msg = "Ha ocurrido un problema, intenta nuevamente";
        });
    },
    verCaso(alerta) {
      if (this.nameState(alerta) != "Programada") {
        return false;
      } else {
        return true;
      }
    },
    nameState(alerta) {
      if (alerta.fecha_notificacion != null) {
        let current_date = new Date();
        let shipping_date = new Date(alerta.fecha_notificacion);
        if (
          shipping_date > current_date &&
          alerta.push_enviado == "0" &&
          alerta.intentos == "0"
        ) {
          return "Programada";
        }
        if (shipping_date < current_date && alerta.push_enviado == "1") {
          return "Enviada";
        }
        if (shipping_date < current_date && alerta.push_enviado == "0") {
          return "Error";
        } else {
          return "Error";
        }
      }
      if (alerta.fecha_notificacion == null) {
        return "Programada";
      }
    },
    // Obtiene YYYY/MM/DD de una fecha
    get_date(fecha_notificacion) {
      let fecha = new Date(fecha_notificacion);
      let año = fecha.getFullYear().toString();
      let mes = fecha.getMonth() + 1;
      mes = mes.toString();
      let dia = fecha.getDate().toString();
      return año + "/" + mes + "/" + dia;
    },
    // Obtiene hora y minutos HH:MM
    get_hours(fecha_notificacion) {
      const date = moment.utc(fecha_notificacion);
      return date.format('HH:mm');
    },
    // Cierra modal status y redirecciona a list
    closeStatus() {
      this.$router.push({ name: "push-list" });
    },
    onCancel() {
      this.clean();
      this.$router.push({ name: "push-list" });
    },
    clean() {
      this.open_select_list = false;
      this.select = {
        workplace: "",
        area: "",
        sindicato: "",
        gender: "",
        module: "",
      };
      this.mensaje = "";
      this.calendar_switch = false;
      this.dateTime = "";
      this.key_calendar = 0;
      this.calendar = "";
      this.calendar_id = "";
    },
    comprobarAnd() {
      return (
        // Segemntacion
        (this.sel_segmento.value == "Transversal"
          ? true
          : this.select_filter.id != "") &&
        // mensaje
        this.mensaje != "" &&
        // programar alerta
        (this.datetime_valited != "" || this.calendar_switch == false)
      );
    },
    comprobarOr() {
      return (
        (this.sel_segmento.value == "Transversal"
          ? false
          : this.select.workplace.id != "" ||
            this.select.area.id != "" ||
            this.select.sindicato.id != "" ||
            this.select.gender.id != "") ||
        this.mensaje != "" ||
        this.select.module.id != "" ||
        (this.calendar_switch ? this.calendar_id != "" : false)
      );
    },
    // Funcion para abrir y cerrar listas de selects
    toggleSelect(selected) {
      if (selected == this.open_select_list) {
        this.open_select_list = false;
      } else {
        this.open_select_list = selected;
      }
    },
    // Obtener sucursales
    async getSucursales() {
      let data_ = {
        id_empresa: this.id_empresa,
      };
      await this.getSucursalesAlerta(data_);
    },
    // Obtener areas
    async getAreas() {
      let data_ = {
        id_empresa: this.id_empresa,
      };
      await this.getAreasAlerta(data_);
    },
    // obtener sindicatos
    async getSindicatos() {
      let data_ = {
        id_empresa: this.id_empresa,
      };
      await this.getSindicatosAlerta(data_);
    },
    // obtener modulos
    async getModules() {
      let data_ = {
        id_empresa: this.id_empresa,
        id_privilegio: "3",
        id_usuario: this.id_usuario,
      };
      await this.getModulosAlerta(data_);
    },
    selectPlace(item) {
      // Seleccionar Sucursal
      this.open_select_list = false;
      this.select.workplace = {
        id: item.id,
        name: item.name,
      };
    },
    selectArea(item) {
      // Seleccionar Gerencia
      this.open_select_list = false;
      this.select.area = {
        id: item.id,
        name: item.name,
      };
    },
    selectSindicato(item) {
      // Seleccionar Gerencia
      this.open_select_list = false;
      this.select.sindicato = {
        id: item.id,
        name: item.name,
      };
    },
    selectGenero(item) {
      // Seleccionar Gerencia
      this.open_select_list = false;
      this.select.gender = {
        id: item.id,
        name: item.name,
      };
    },
    selectModule(item) {
      // Seleccionar Gerencia
      this.open_select_list = false;
      this.select.module = {
        id: item.id,
        name: item.name,
      };
    },
    // Funcion para seleccionar fecha
    onDayClick(day) {
      if (day.isDisabled == false) {
        this.calendar_id = day.id;
      }
    },
  },
};
</script>
